import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined, PartitionOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { selectContractByParam, selectSettlementNode, setlemntInsertFeedback, setlemntSelectApplyById, setlemntUpdateToApproval, updateSettlementApply, setlemntUpdateReviseApply, selectAllContractProfessional } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import moment from 'moment';
import Cookies from "js-cookie";
import paymentPlanModal from './paymentPlanModal.vue';
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined,
    PartitionOutlined,
    paymentPlanModal
  },
  props: {
    applyDetailData: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['closeApplyVisible', "updateDataValue"],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: '',
      belongProfessional: undefined,
      fileUrl: [],
      itemId: undefined,
      payNode: [],
      totalAmount: null,
      planSettleAmount: null,
      contractApplyId: undefined,
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      content: "",
      //审批意见
      annotation: [],
      //批注后文件
      resource: 0,
      revisePayroll: [],
      //修订后合同
      updateContractList: [],
      //修订后合同集
      reviseCon: [],
      //修改时需要修改修订后合同时用
      description: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      description: [{
        required: true,
        message: '结算描述不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };
    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      } else if (props.applyDetailData.state == 20) {
        return false;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return true;
      } else if (props.applyDetailData.state == 20) {
        return true;
      }
    }); //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const getAdjuncts1 = val => {
      // console.log('getAdjuncts',val)
      formState.annotation = val;
    };

    const getAdjunctsForRev = val => {
      // console.log('getAdjuncts',val)
      formState.revisePayroll = val;
    };

    const getAdjunctsForRevCon = val => {
      // console.log('getAdjuncts',val)
      formState.reviseCon = val;
    }; //审批


    const handlePassOrReject = async num => {
      if (!formState.content && formState.resource == 1) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject,
        fileUrl: JSON.stringify(formState.annotation)
      };
      const res = await setlemntUpdateToApproval(param);

      if (res.code == 200) {
        _message.success('审批成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.payNode.length) {
          _message.error('此合同无付款节点,不可提交');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.payNode = JSON.stringify(obj.payNode);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.revisePayroll = JSON.stringify(obj.reviseCon);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null;
        console.log(obj);
        loading.value = true;
        let res = await updateSettlementApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //修订


    const handleRevise = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.payNode.length) {
          _message.error('此合同无付款节点,不可提交');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.payNode = JSON.stringify(obj.payNode);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null;
        obj.revisePayroll = JSON.stringify(obj.revisePayroll);
        console.log(obj);
        loading.value = true;
        let res = await setlemntUpdateReviseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //项目名称查询


    const projectNameData = ref([]);
    const contractNameData = ref([]);

    const handleSearch = val => {
      fetchForMat(val, null);
    };

    const handleChange = index => {
      formState.contractName = undefined;
      formState.payNode = [];
      formState.totalAmount = null;
      formState.planSettleAmount = null;
      formState.belongProfessional = [];
    }; //合同名查询


    const handleSearchForCot = val => {
      fetchForMat1(formState.projectName, val);
    };

    const fetchForMat = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 3
      }); // console.log(res)

      try {
        projectNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            projectNameData.value.push({
              value: ixt.projectName,
              text: ixt.projectName
            });
          });
        }

        projectNameData.value = uniqueJsonArray(projectNameData.value, 'text');
      } catch (error) {
        console.log(error);
      }
    };

    const fetchForMat1 = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 3
      }); // console.log(res)

      try {
        contractNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            contractNameData.value.push({
              value: ixt.contractName,
              text: ixt.contractName,
              applyId: ixt.id
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //获取付款节点和所属专业


    const handleChangeForCot = val => {
      // console.log(val)
      if (val) {
        formState.contractApplyId = contractNameData.value.find(item => item.value == val).applyId;
        getPaymentNode(formState.contractApplyId); // getProfessional(formState.contractApplyId)
      } else {
        formState.payNode = [];
        formState.totalAmount = null;
        formState.planSettleAmount = null;
        formState.belongProfessional = [];
      }
    };

    const getPaymentNode = async id => {
      const res = await selectSettlementNode({
        applyId: id
      });

      try {
        if (res.data.length) {
          formState.payNode = [];
          formState.totalAmount = null;
          formState.planSettleAmount = null;
          res.data.forEach(item => {
            let obj = {
              nodeDesc: item.nodeDesc,
              payTerms: item.payTerms,
              // actualPayAmount: item.actualPayAmount,
              actualPayAmount: item.payAmount
            };
            formState.totalAmount += item.payAmount;
            formState.planSettleAmount += item.payAmount;
            formState.payNode.push(obj);
          });
        } else {
          _message.error('暂无付款节点');
        }
      } catch (error) {
        console.log(error);
      }
    };

    const getProfessional = async id => {
      let res = await selectAllContractProfessional({
        applyId: id
      });

      if (res.code === 200) {
        formState.belongProfessional = res.data == "" ? [] : res.data.split(",");
      } else {
        _message.error(res.message);
      }
    }; //修改付款节点后自动修改总金额


    const handleChangePayAmound = () => {
      formState.totalAmount = null;
      formState.payNode.forEach(item => {
        formState.totalAmount += item.actualPayAmount;
      });
    }; //获取详情数据


    const getData = async key => {
      const res = await setlemntSelectApplyById(key);

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        formState.teamMember = JSON.parse(formState.teamMember);
        formState.belongProfessional = formState.belongProfessional ? formState.belongProfessional.split(',') : undefined;
        formState.payNode = JSON.parse(formState.payNode);
        formState.payNode.forEach(item => {
          item.key = item.serialNo;
          item.planPayTime = moment(item.planPayTime, 'YYYY-MM-DD');
        });
        formState.reviseCon = formState.updateContractList.length ? JSON.parse(formState.updateContractList[formState.updateContractList.length - 1].fileUrl) : [];
        formState.revisePayroll = formState.revisePayroll == null ? [] : formState.revisePayroll;
        console.log(formState);
      } catch (error) {
        console.log('结算详情:', error);
      }
    }; //收支反馈


    const paymentOrRequest = async state => {
      let res = await setlemntInsertFeedback({
        state,
        applyId: props.applyDetailData.key
      });

      if (res.code === 200) {
        _message.success(res.message);

        formRef.value.resetFields();
        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    }; //合同是否可编辑


    const disabledForContract = computed(() => {
      if (formState.projectName && !isDisabledForCl.value) {
        return false;
      } else {
        return true;
      }
    }); //修改模式是否显示最后一次修订合同是否可修改

    const isModifyForCot = index => {
      if (formState.updateContractList.length && props.applyDetailData.state == 2 && index == formState.updateContractList.length - 1) {
        return false;
      } else {
        return true;
      }
    }; //修改模式是否显示变更单是否显示可修改


    const isShowFileUrlByUp = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return false;
        } else {
          return true;
        }
      } else if (props.applyDetailData.state !== 2) {
        return false;
      }
    });
    const isShowFileUrl = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return true;
        } else {
          return false;
        }
      } else if (props.applyDetailData.state !== 2) {
        return true;
      }
    });
    const planVisible = ref(false); //关闭收支计划弹窗

    const closePlanVisible = val => {
      planVisible.value = val;
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 7,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const lineIsShowFileUrl = val => {
      let arr = JSON.parse(val);
      console.log(arr);

      if (arr && arr.length) {
        return true;
      } else {
        return false;
      }
    };

    onMounted(() => {
      getApprovalItem();
      getData(props.applyDetailData.key);
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      // disabledDate,
      handleSearch,
      projectNameData,
      money,
      handleSearchForCot,
      contractNameData,
      handleChange,
      handleChangeForCot,
      handleChangePayAmound,
      isDisabledForCl,
      isDisabledForSp,
      getAdjuncts1,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleRevise,
      getAdjunctsForRev,
      paymentOrRequest,
      disabledForContract,
      isModifyForCot,
      isShowFileUrlByUp,
      isShowFileUrl,
      getAdjunctsForRevCon,
      planVisible,
      closePlanVisible,
      projectOptions,
      professionalOptions,
      getProfessionalByItem,
      lineIsShowFileUrl
    };
  }

});