import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, onMounted, computed } from 'vue';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import { selectJobCascade, selectVerifierSetting, updateVerifierSetting } from '@/api/material';
import Cookies from "js-cookie";
export default defineComponent({
  props: {
    respVisible: {
      type: Boolean,
      default: false
    },
    respData: {
      type: Object,
      default: () => ({
        professionals: [],
        resePluss: [],
        warehouses: [],
        itemId: null,
        itemName: null
      })
    }
  },
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined
  },
  emits: ['closeRespVisible'],

  setup(props, context) {
    const closeRespVisible = () => {
      context.emit('closeRespVisible', false);
    }; //添加责任人


    const addResp = () => {
      formState.resePluss.push([]);
    }; //删除责任人


    const deleteResp = index => {
      formState.resePluss.splice(index, 1);
    };

    const loading = ref(false);
    const formRef = ref();
    const formState = reactive({
      itemName: props.respData.itemName,
      itemId: props.respData.itemId,
      professionals: props.respData.professionals.length ? props.respData.professionals : [{
        id: null,
        name: null,
        value: null
      }],
      resePluss: props.respData.resePluss.length ? props.respData.resePluss : [[]],
      finances: props.respData.finances
    });
    const rules = {
      itemName: [{
        required: true,
        message: '审批流程线名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    }; //提交

    const handleOk = () => {
      formRef.value.validate().then(async () => {
        // console.log(jobsAndresp.value.finances)
        // console.log(jobsAndresp.value.rests)
        loading.value = true;
        const param = {
          type: 7,
          belongProject: Number(Cookies.get("belongProject")),
          professionals: [],
          resePluss: [],
          warehouses: [],
          itemName: formState.itemName,
          itemId: formState.itemId ? formState.itemId : null
        };
        param.professionals = getObj(formState.professionals, 1);
        param.resePluss = getObj1(formState.resePluss, 0);
        param.finances = getObj(formState.finances, 0);

        try {
          let res = await updateVerifierSetting(param);

          if (res.code == 200) {
            _message.success(res.message);

            context.emit('closeRespVisible', false);
            loading.value = false;
          } else {
            _message.error(res.message);

            loading.value = false;
          }
        } catch (error) {
          loading.value = false;
          console.log(error);
        }
      });
    };

    const getObj = (val, num) => {
      let arrData = [];
      val.forEach(item => {
        // console.log(item)
        let arr = num == 1 ? item.value[1].split('-') : item[1].split('-');
        let obj = {
          professionalId: item.id ? item.id : null,
          professionalName: item.name ? item.name : null,
          projectId: Number(arr[1]),
          idCard: arr[0],
          jobId: num == 1 ? item.value[0] : item[0],
          belongProject: Number(Cookies.get("belongProject")),
          userName: arr[2]
        }; // console.log(obj)

        arrData.push(obj);
      });
      return arrData;
    };

    const getObj1 = (val, num) => {
      let arrDataAll = [];
      val.forEach(item => {
        let arrData = [];
        item.forEach(ixt => {
          let arr = num == 1 ? ixt.value[1].split('-') : ixt[1].split('-');
          let obj = {
            professionalId: ixt.id ? ixt.id : null,
            professionalName: ixt.name ? ixt.name : null,
            projectId: Number(arr[1]),
            idCard: arr[0],
            jobId: num == 1 ? ixt.value[0] : ixt[0],
            belongProject: Number(Cookies.get("belongProject")),
            userName: arr[2]
          }; // console.log(obj)

          arrData.push(obj);
        });
        arrDataAll.push(arrData);
      });
      return arrDataAll;
    };

    const change = (val, selectedOptions) => {
      console.log(val, selectedOptions);
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      const belongProject = Number(Cookies.get("belongProject"));
      let res = await selectJobCascade({
        belongProject
      });

      if (res.code === 200) {
        // console.log(res)
        const jobCorporations = res.data.jobCorporations; //总公司管理岗集合

        const jobProjects = res.data.jobProjects; //本项目管理岗集合
        // if(Number(Cookies.get("belongProject")) !== 10000){
        //   options.value = [
        //     {
        //       value:Number(Cookies.get("belongProject")),
        //       label: res.data.projectName,
        //       children: conversion(jobProjects)
        //     },
        //     {
        //       value:10000,
        //       label: '总公司',
        //       children: conversion(jobCorporations)
        //     }
        //   ]
        // }else {
        //   options.value = [
        //     {
        //       value:10000,
        //       label: '总公司',
        //       children: conversion(jobCorporations)
        //     }
        //   ]
        // }

        options.value = conversion(jobProjects); // console.log(options.value)
      }
    };

    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: `${ixt.uid}-${ixt.belongProject}-${ixt.userName}`,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const addProfessional = () => {
      // console.log(jobsAndresp.value.professionals)
      formState.professionals.push({
        id: null,
        name: null,
        value: null
      });
    };

    const deleteMajor = index => {
      if (formState.professionals.length === 1) {
        return _message.error('专业/部门审批责任人不能全部删除！');
      }

      formState.professionals.splice(index, 1);
    };

    onMounted(() => {
      getJobCascade();
    });
    return {
      options,
      closeRespVisible,
      addResp,
      deleteResp,
      loading,
      handleOk,
      change,
      formRef,
      formState,
      rules,
      addProfessional,
      deleteMajor
    };
  }

});